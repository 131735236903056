
%box,
.box {

    &--natural {
        padding-top: $bsu-s;
        padding-bottom: $bsu-s;

    }

}

%box, .box {
    @extend .box--natural;
}

