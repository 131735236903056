
html, body {

    min-height: 100%;
    // @include inuit-font-size($inuit-base-font-size);
    font-family: 'Open Sans', sans-serif;

    font-size: $inuit-base-font-size;
    line-height: 1.7142857143;
}

a {
    text-decoration: none;
    color: $brand-alt-color;

    &:hover {
        color: $brand-color;
    }
}

ul, dl {
    margin-left: $bsu-l;

    ul {
        margin-bottom: 0;
    }
}

article {
    margin-bottom: $bsu;
}

#scrollToTop {
    @extend .btn;
    @extend .btn--small;

    @include inuit-font-size(13px);

    position: fixed;
    bottom: $bsu-l;
    right: $bsu;

    box-shadow: 0 0 2px $color-gray;

    opacity: 0;
    pointer-events: none;

    transition: opacity .2s ease-in;

    &:before {
        content: none !important;
    }

    &.scrollToTop--visible {
        opacity: 1;
        pointer-events: auto;
    }
}

img[src*=".svg"] {
  width: 100%;
}

iframe { border: 0 !important; }
