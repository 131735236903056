
.matrix {
    display: table;
    width: 100%;
    table-layout: fixed;

    &__row {
        display: table-row;
        vertical-align: top;
    }

    &__item {
        @extend .box--small;
        display: table-cell;

        &[class*="lap-and-up"] {
            display: block;
        }

        @include media-query(lap-and-up) {

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }

            &[class*="lap-and-up"] {
                // display: block;
                display: table-cell;
            }

        }
    }

}
