
.page-navigation {

    padding-left: $bsu; // - $bsu-xs;
    padding-right: $bsu; // - $bsu-xs;

    background: $color-gray;

    @include media-query(lap-and-up) {
        background: none;
    }

    &__menu {
        display: inline-block;
        width: 100%;

        padding: $bsu-xs;

        color: white;
        text-align: center;

        cursor: pointer;

        @include media-query(lap-and-up) {
            display: none; /* TODO */
        }
    }

    &__list {
        @extend .list-bare;
        @extend .list-ui;
        @extend .list-ui--tiny;

        display: none;

        border-top-width: 0;
        width: 100%;

        @include media-query(lap-and-up) {
            display: table;
            @include flyout('.menu-item-has-children', '.page-navigation__sub');
        }

        > li {
            display: inline-block;
            width: 100%;
            border-color: white;

            > a {
                display: block;

                margin: -$bsu-xs;
                padding: $bsu-xs $bsu-s;

                color: white;
            }

            @include media-query(lap-and-up) {
                display: table-cell;
                width: auto;

                border-bottom-width: 0;

                > a {
                    display: block;
                    margin-right: $bsu-xs;

                    // background-color: rgba($brand-color, 0.92);
                    background-color: $color-gray-dark;
                    transition: background .2s ease-in;

                    &:hover {
                        background: $color-gray;
                    }
                }

                &.current_page_item, &.current {
                    > a {
                        background: $color-gray;
                    }
                }

                &.menu-item-has-children {
                    > a {
                        position: relative;

                        &:after {
                            position: absolute;
                            right: $bsu-s;

                            content: "\e603";
                            font-family: 'icomoon';

                            color: $brand-alt-color;

                            transition: all .2s ease-in-out;
                        }
                    }

                    .page-navigation__sub {
                        opacity: 0;
                        transition: opacity .2s ease-in-out;
                    }

                    &:hover {
                        > a {

                            &:after {

                                content: "\e600";

                                color: $brand-alt-color;
                            }
                        }
                        .page-navigation__sub {
                            opacity: 1;
                        }
                    }
                }

                &.page-navigation--two-col {

                    > .page-navigation__sub {
                        width: 200%;

                        li {
                            float: left;
                            display: inline-block;
                            width: 50%;

                            white-space: nowrap;

                            &:nth-child(2n) {
                                border-left: 1px solid white;
                            }

                        }

                    }
                }

            }


            &:last-child {
                border-bottom-width: 0;

                @include media-query(lap-and-up) {
                    padding-right: 0;

                    a {
                        margin-right: 0;
                    }

                    .page-navigation__sub {
                        width: 100%;

                        a {
                            margin-right: -$bsu-xs;
                        }
                    }
                }
            }

        }
    }

    &__sub {

        @extend .visuallyhidden--palm;
        @extend .visuallyhidden--palm-wide;
        @extend .clearfix;

        @include media-query(lap-and-up) {
            width: 100%;
            width: calc(100% - #{$bsu-s});
        }

        &__list {
            @extend .list-bare;
            @extend .list-ui;
            @extend .list-ui--tiny;

            float: left;
            width: 100%;

            border-top-color: white;

            > li {
                border-color: white;
                // float: left;

                > a {
                    display: block;

                    margin: -$bsu-xs;
                    padding: $bsu-xs $bsu-s;

                    color: white;
                    background: rgba($brand-alt-color, .9);

                    &:hover {
                        background: rgba(darken($brand-alt-color, 5), .9);
                    }
                }

            }
        }

    }

    .children {
        // display: none;
    }
}
