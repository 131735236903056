.page-subnavigation--fixed {
    @include media-query(lap-and-up) {
        position: fixed;
        top: 0px; //20px
        bottom: auto;
    }
}

.page-subnavigation--absolute {
    @include media-query(lap-and-up) {
        position: absolute;
        top: auto;
        bottom: 0;
    }
}


.page-subnavigation, .scroll-nav__wrapper {

    padding: $bsu $bsu-s;

    background-color: $brand-alt-color;


    @include media-query(portable) {
        padding: $bsu $bsu-s;
    }

    @include media-query(lap-and-up) {
        background: none;
    }


    &__list, .scroll-nav__list {
        @extend .list-bare;
        @extend .zeta;
        @extend .link-list;
        @extend .link-list--dot;

        @include media-query(lap-and-up) {
            transition: top .2s ease-in-out;
            top: 0;

            &--fixed {
                position: fixed;
                top: $bsu;

            }
        }
        > li {
            display: inline-block;
            width: auto;

            color: white;

            ul {
                @extend .page-subnavigation__list;
                display: none;
            }

            @include media-query(lap-and-up) {
                width: 100%;

                a {
                    // padding-left: 0;
                    // padding-right: 0;
                }
            }

            > a {
                // @extend .icon;
                // @extend .icon-arrow-right;
                &:before {
                    @include inuit-font-size(13px, false);
                    // margin-right: $bsu-xs;
                }

                color: inherit;
                display: block;
                // padding: $bsu-xs;

                transition: color .1s ease-in-out;

                &:hover {
                    color: $color-gray;
                }

            }

            &.current_page_item, &.active {
                > a {
                    font-weight: 700;

                    &:before {
                        content: "\e600";
                    }
                }

                > ul {
                    display: block;
                }
            }
        }

    }
}
