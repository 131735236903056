
.share-list {
    // @extend .list-bare;
    @extend .list-block;
    @extend .list-block--tiny;

    margin-bottom: $bsu;

    &__item {

        @extend .zeta;

        a {
            cursor: pointer;
            color: $color-gray;

            transition: color .1s ease-in-out;

            &:hover {
                color: $brand-alt-color;
            }
        }

        .icon {
            @include inuit-font-size(20px, false);
        }
    }

    > li {
        @extend .share-list__item;
    }

    &--inline {
        @extend .list-inline;
        @extend .list-block;
    }

    &--inline--small {
        @extend .list-inline;
        @extend .list-block;

        @include media-query(lap-and-up) {
            > li {
                display: block;
            }
        }
    }
}
