

@media screen and (max-width: $inuit-media-collapse-at) {

    .media--large {
        &.media--responsive {
            .media__img {
                margin-bottom: $bsu;
            }
        }
    }
}
