/**

**/

.accordion {
    position: relative;

    &__action {
        cursor: pointer;

        &:hover {
            color: $brand-alt-color;
        }
    }

    &__body {
        // height: 0;
        // max-height: 0;
        // overflow: hidden;
        padding: 0;
        display: none;

        // transition: max-height .2s ease-in-out, opacity .3s ease-in-out;

    }

    &--indicator {
        .accordion__action {
            &:after {
                @extend .icon;
                content: "\e600";
                position: absolute;
                right: 0;
                top: 3.5px;

                transition: .3s transform ease-in-out;
            }
        }
        &.accordion--active {
            .accordion__action {
                &:after {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &--active {
        .accordion__body {
            height: auto;
            display: block;
        }
    }

    &--read-more {
        .accordion__action {
            @extend .btn;
            @extend .btn--small;

            position: absolute;
            bottom: $bsu;

            &:before {
                content: "\e600" !important;
            }
            &:after {
                font-family: 'Open Sans', sans-serif;
                content: attr(data-more) !important;
            }
        }

        padding-bottom: $bsu-l !important;

        &.accordion--active {
            .accordion__action {
                &:before {
                    content: "\e603" !important;
                }
                &:after{
                    content: attr(data-less) !important;
                }
            }
            .accordion__body {
            }
        }
    }
}

