
figure {
    @extend .figure;
}

    figcaption {
        @extend .figure__caption;
    }


.figure,
%figure {
    // max-width: 250px;

    > img {
        width: 100%;
        max-width: none !important;
    }

    &__caption {
        padding: $bsu-xs;

        color: $brand-color;
        // background: $color-blue-20;

        // @include inuit-font-size(13px, 1.4);
    }
}


