
.layout,
%layout {
    @extend %clearfix;
}

    .layout__item, %layout__item {
        float: left;
    }

.layout--rev,
%layout--rev {

    > .layout__item, %layout__item {
        float: right;
    }
}
