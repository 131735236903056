/*------------------------------------*\
    #WIDTHS-RESPONSIVE
\*------------------------------------*/

/**
 * Responsive width classes based on your responsive settings.
 */

@if $inuit-use-fractions == true {

    @each $breakpoint in $breakpoints {

        $alias:     nth($breakpoint, 1);
        $condition: nth($breakpoint, 2);

        // This isn’t ideal, but we definitely don’t want to generate widths
        // for retina devices. Exclude retina media-qeuries manually.
        @if ($alias != "retina") {
    
            @include media-query($alias) {

                /**
                 * Whole.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/1"]     { width: 100% !important; }
                
                /**
                 * Halves.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/2"],
                [class~="#{$inuit-widths-namespace}#{$alias}-2/4"],
                [class~="#{$inuit-widths-namespace}#{$alias}-3/6"],
                [class~="#{$inuit-widths-namespace}#{$alias}-4/8"],
                [class~="#{$inuit-widths-namespace}#{$alias}-5/10"],
                [class~="#{$inuit-widths-namespace}#{$alias}-6/12"]     { width: 50% !important; }
                
                /**
                 * Thirds.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/3"],
                [class~="#{$inuit-widths-namespace}#{$alias}-2/6"],
                [class~="#{$inuit-widths-namespace}#{$alias}-3/9"],
                [class~="#{$inuit-widths-namespace}#{$alias}-4/12"]     { width: 33.3333333% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-2/3"],
                [class~="#{$inuit-widths-namespace}#{$alias}-4/6"],
                [class~="#{$inuit-widths-namespace}#{$alias}-6/9"],
                [class~="#{$inuit-widths-namespace}#{$alias}-8/12"]     { width: 66.6666666% !important; }
                
                /**
                 * Quarters.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/4"],
                [class~="#{$inuit-widths-namespace}#{$alias}-2/8"],
                [class~="#{$inuit-widths-namespace}#{$alias}-3/12"]     { width: 25% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-3/4"],
                [class~="#{$inuit-widths-namespace}#{$alias}-6/8"],
                [class~="#{$inuit-widths-namespace}#{$alias}-9/12"]     { width: 75% !important; }
                
                /**
                 * Fifths.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/5"],
                [class~="#{$inuit-widths-namespace}#{$alias}-2/10"]     { width: 20% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-2/5"],
                [class~="#{$inuit-widths-namespace}#{$alias}-4/10"]     { width: 40% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-3/5"],
                [class~="#{$inuit-widths-namespace}#{$alias}-6/10"]     { width: 60% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-4/5"],
                [class~="#{$inuit-widths-namespace}#{$alias}-8/10"]     { width: 80% !important; }
                
                /**
                 * Sixths.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/6"],
                [class~="#{$inuit-widths-namespace}#{$alias}-2/12"]     { width: 16.6666666% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-5/6"],
                [class~="#{$inuit-widths-namespace}#{$alias}-10/12"]    { width: 83.3333333% !important; }
                /**
                 * Eighths.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/8"]      { width: 12.5% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-3/8"]      { width: 37.5% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-5/8"]      { width: 62.5% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-7/8"]      { width: 87.5% !important; }
                
                /**
                 * Ninths.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/9"]      { width: 11.1111111% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-2/9"]      { width: 22.2222222% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-4/9"]      { width: 44.4444444% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-5/9"]      { width: 55.5555555% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-7/9"]      { width: 77.7777777% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-8/9"]      { width: 88.8888888% !important; }
                
                /**
                 * Tenths.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/10"]     { width: 10% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-3/10"]     { width: 30% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-7/10"]     { width: 70% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-9/10"]     { width: 90% !important; }
                
                /**
                 * Twelfths.
                 */
                [class~="#{$inuit-widths-namespace}#{$alias}-1/12"]     { width:  8.3333333% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-5/12"]     { width: 41.6666666% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-7/12"]     { width: 58.3333333% !important; }
                [class~="#{$inuit-widths-namespace}#{$alias}-11/12"]    { width: 91.6666666% !important; }

            } // Close media query.

        } // Close retina check.

    } // Close breakpoints loop.

} // Close fractions @if.





@else {

    @each $breakpoint in $breakpoints {

        $alias:     nth($breakpoint, 1);
        $condition: nth($breakpoint, 2);

        // This isn’t ideal, but we definitely don’t want to generate widths
        // for retina devices. Exclude retina media-qeuries manually.
        @if ($alias != retina) {
    
            @include media-query($alias) {

                /**
                 * Whole.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-whole          { width: 100% !important; }
                
                /**
                 * Halves.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-half,
                .#{$inuit-widths-namespace}#{$alias}-two-quarters,
                .#{$inuit-widths-namespace}#{$alias}-three-sixths,
                .#{$inuit-widths-namespace}#{$alias}-four-eighths,
                .#{$inuit-widths-namespace}#{$alias}-five-tenths,
                .#{$inuit-widths-namespace}#{$alias}-six-twelfths       { width: 50% !important; }
                
                /**
                 * Thirds.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-third,
                .#{$inuit-widths-namespace}#{$alias}-two-sixths,
                .#{$inuit-widths-namespace}#{$alias}-three-ninths,
                .#{$inuit-widths-namespace}#{$alias}-four-twelfths      { width: 33.3333333% !important; }
                .#{$inuit-widths-namespace}#{$alias}-two-thirds,
                .#{$inuit-widths-namespace}#{$alias}-four-sixths,
                .#{$inuit-widths-namespace}#{$alias}-six-ninths,
                .#{$inuit-widths-namespace}#{$alias}-eight-twelfths     { width: 66.6666666% !important; }
                
                /**
                 * Quarters.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-quarter,
                .#{$inuit-widths-namespace}#{$alias}-two-eighths,
                .#{$inuit-widths-namespace}#{$alias}-three-twelfths     { width: 25% !important; }
                .#{$inuit-widths-namespace}#{$alias}-three-quarters,
                .#{$inuit-widths-namespace}#{$alias}-six-eighths,
                .#{$inuit-widths-namespace}#{$alias}-nine-twelfths      { width: 75% !important; }
                
                /**
                 * Fifths.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-fifth,
                .#{$inuit-widths-namespace}#{$alias}-two-tenths         { width: 20% !important; }
                .#{$inuit-widths-namespace}#{$alias}-two-fifths,
                .#{$inuit-widths-namespace}#{$alias}-four-tenths        { width: 40% !important; }
                .#{$inuit-widths-namespace}#{$alias}-three-fifths,
                .#{$inuit-widths-namespace}#{$alias}-six-tenths         { width: 60% !important; }
                .#{$inuit-widths-namespace}#{$alias}-four-fifths,
                .#{$inuit-widths-namespace}#{$alias}-eight-tenths       { width: 80% !important; }
                
                /**
                 * Sixths.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-sixth,
                .#{$inuit-widths-namespace}#{$alias}-two-twelfths       { width: 16.6666666% !important; }
                .#{$inuit-widths-namespace}#{$alias}-five-sixths,
                .#{$inuit-widths-namespace}#{$alias}-ten-twelfths       { width: 83.3333333% !important; }
                
                /**
                 * Eighths.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-eighth         { width: 12.5% !important; }
                .#{$inuit-widths-namespace}#{$alias}-three-eighths      { width: 37.5% !important; }
                .#{$inuit-widths-namespace}#{$alias}-five-eighths       { width: 62.5% !important; }
                .#{$inuit-widths-namespace}#{$alias}-seven-eighths      { width: 87.5% !important; }
                
                /**
                 * Ninths.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-ninth          { width: 11.1111111% !important; }
                .#{$inuit-widths-namespace}#{$alias}-two-ninths         { width: 22.2222222% !important; }
                .#{$inuit-widths-namespace}#{$alias}-four-ninths        { width: 44.4444444% !important; }
                .#{$inuit-widths-namespace}#{$alias}-five-ninths        { width: 55.5555555% !important; }
                .#{$inuit-widths-namespace}#{$alias}-seven-ninths       { width: 77.7777777% !important; }
                .#{$inuit-widths-namespace}#{$alias}-eight-ninths       { width: 88.8888888% !important; }
                
                /**
                 * Tenths.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-tenth          { width: 10% !important; }
                .#{$inuit-widths-namespace}#{$alias}-three-tenths       { width: 30% !important; }
                .#{$inuit-widths-namespace}#{$alias}-seven-tenths       { width: 70% !important; }
                .#{$inuit-widths-namespace}#{$alias}-nine-tenths        { width: 90% !important; }
                
                /**
                 * Twelfths.
                 */
                .#{$inuit-widths-namespace}#{$alias}-one-twelfth        { width:  8.3333333% !important; }
                .#{$inuit-widths-namespace}#{$alias}-five-twelfths      { width: 41.6666666% !important; }
                .#{$inuit-widths-namespace}#{$alias}-seven-twelfths     { width: 58.3333333% !important; }
                .#{$inuit-widths-namespace}#{$alias}-eleven-twelfths    { width: 91.6666666% !important; }

            } // Close media query.

        } // Close retina check.

    } // Close breakpoints loop.

} // Close fractions @if.
