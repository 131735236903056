
.link-list {

    a {
        display: block;
        transition: all .1s ease-in-out;

        &:hover {
            color: $brand-alt-color;
        }
    }

    > li {
        padding: $bsu-xs $bsu-s;
        padding-left: $bsu;

        > a {
            margin: neg($bsu-xs) neg($bsu-s);
            padding: $bsu-xs $bsu-s;

            padding-left: $bsu;
            margin-left: neg($bsu);

        }

        @include media-query(lap-and-up) {
            padding-top: $bsu-xs;
            padding-bottom: $bsu-xs;

            > a {
                margin-top: neg($bsu-xs);
                margin-bottom: neg($bsu-xs);

                padding-top: $bsu-xs;
                padding-bottom: $bsu-xs;
            }
        }
    }

    &--bordered {
        border: 1px solid $brand-color;
        @include media-query(lap-and-up) {
            border: 0;
        }
    }

    &--dot {
        > li {
            > a {
                &:before {
                    @extend .icon;
                    content: "\e602";

                    margin-right: 0px;
                    margin-left: neg($bsu-s);

                    position: relative;
                    left: neg($bsu-xs);

                }
            }
        }
    }
}

