
.page-container {

    width: 98%;
    max-width: $container-max;
    margin: 0 auto;

    @include media-query(lap-and-up) {
        width: 95%;
    }

    &--home {
        .page-navigation {
            border-top: 1px solid $brand-color;
            border-bottom: 1px solid $brand-color;
        }

        @include media-query(lap-and-up) {
            .page-navigation {
                top: -($bsu + $bsu-s);
                margin-bottom: -($bsu + $bsu-s);

                border: 0;
            }
        }

        .page-main {
            padding-bottom: $bsu;

            @include media-query(lap-and-up) {
                padding-top: $bsu;
                padding: $bsu + $bsu-xs;
            }
        }

        .page-main__content {
            margin: 0;
            padding: $bsu $bsu-s;

            color: white;
            background: none;

        }
    }
}

@include media-query(lap-and-up) {

    .page-navigation, .page-main, .page-main__headline {
        position: relative;
    }

    .page-navigation {
        top: -(3*$bsu + $bsu-s);
        margin-bottom: -($bsu + $bsu-s);
    }

    .page-main__headline {
        top: -(2*$bsu);
        margin-bottom: -($bsu + $bsu);
    }
}



.page-main {

    min-height: 100%;

    background-color: $color-gray;

    &__content {
        background: white;

        @include media-query(lap-and-up) {
            margin-left: $bsu;
            margin-right: $bsu;

            // background: linear-gradient(to right, white 0%,  white 77.999%, $brand-alt-color 78%);
        }
    }

    &__headline {
        padding: $bsu-s $bsu;

        background: rgba(#fff, 0.8);

        > h1 { margin-bottom: 0; }

        @include media-query(lap-and-up) {
            padding-top: $bsu-xs;
            padding-bottom: $bsu-xs;
            margin-left: $bsu;
            margin-right: $bsu;
        }
    }

}

.page-sidebar {
    overflow: hidden;

    position: relative;
}


.page-content {


    &__sidebar {
        background: $brand-alt-color;
    }


    @include media-query(lap-and-up) {
        display: table;
        direction: rtl;

        width: 100%;
        table-layout: fixed;

        &__sidebar, &__main {
            display: table-cell;
            direction: ltr;

            vertical-align: top;
        }

        &__sidebar {
            width: 25%;
        }

        &__main {
            padding-right: $bsu-l;

            @include media-query(desk) {
                padding-right: 2*$bsu-l;
            }
        }

    }
}
