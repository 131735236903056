

$brand-color: #3c3b30;
$brand-alt-color: #009fe3;

$color-gray: #4f5355;
$color-gray-dark: #2c2d2d;

$inuit-list-ui-border-color:    $brand-alt-color;

$colors: "brand" $brand-color,
         "brand-alt" $brand-alt-color,
         "gray" $color-gray,
         "gray-dark" $color-gray-dark,
         "white" #ffffff,
         "black" #000000,
         "red" red;


