
.align {
    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }
}

.uppercase,
%uppercase {
    text-transform: uppercase;
    letter-spacing: 0.01em;
}

@include generate-colors();

@each $state, $query  in $breakpoints {
    @include media-query($state) {
        .visuallyhidden--#{$state} {
            @include visuallyhidden();
        }
    }
}


.map-container {
    width: 100%;
    height: 100%;

    > iframe {
        width: 100%;
        height: 252px;
    }
}



.weight {

    &--bold {
        font-weight: 700;
    }

}

.float {
    &--left {
        float: left;
    }
    &--right {
        float: right;
    }
}

.hidden {
    display: none;
}
