
.list {
    @extend .list-ui;
    @extend .list-ui--small;


    &__title {
        @extend .lede;
        margin-bottom: 0;

        font-weight: 700;
    }

    ul {
        margin-bottom: 0;
    }

    &--dl {
        position: relative;

        dt, dd {
            padding: $bsu-s;
        }

        dt {
            display: inline-block;
            float: left;
        }
        dd {

            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;

                display: block;
                padding-top: $bsu-s;
                border-bottom: 1px solid $brand-alt-color;
            }
        }
        &--large {
            dd {
                margin-left: $bsu-xl;
            }
        }
    }
}


