/**

idea from inuit.css

**/

.flyout,
.flyout--alt {
    position: relative;
    overflow: hidden;
}

.flyout--bigmenu {
    position: static;
}

    .flyout__content {
        position: absolute;
        top: 100%;
        left: -99999px;
    }

    .flyout:hover {
        overflow: visible;
    }

    .flyout:hover > .flyout__content {
        left: 0;
    }

    .flyout--alt > .flyout__content {
        bottom: 100%;
        top: auto;
    }

    .flyout--bigmenu:hover > .flyout__content {
        max-width: $container-max;
        background: white;
    }


