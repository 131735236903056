

@mixin visuallyhidden {
    border:0!important;
    clip:rect(0 0 0 0)!important;
    height:1px!important;
    margin:-1px!important;
    overflow:hidden!important;
    padding:0!important;
    position: absolute!important;
    width:1px!important;
}


@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


@mixin reset-pseudo() {
    &:before, &:after {
        content: none;
    }
}

@mixin truncate($truncation-boundary){
    max-width:$truncation-boundary;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}


@mixin generate-colors() {
    @each $name, $color in $colors {
        .color-#{$name} {
            color: $color;
        }
    }
}

.color-brand-alt {
    color: $brand-alt-color !important;
}



@mixin flyout($element, $content) {

    #{$element} {
        position: relative;
        overflow: hidden;

        &:hover {
            overflow: visible;
        }
    }

    #{$content} {
        position: absolute;
        bottom: 100%;
        // top: -1000px;
        left: -9999px;
        // z-index: 5;
    }

    #{$element}:hover > #{$content} {
        // top: 100%;
        left: 0;
        // width: 100%;
    }

}
