
.page-header {

    padding-top: $bsu-s;

    &__top {
        @extend .clearfix;
    }
}

.brand {
    padding: 0 $bsu;
    // float: left;
    float: right;

    @include media-query(palm-wide) {
        max-width: 195px;
    }
    @include media-query(lap-and-up) {
        max-width: 250px;
    }
}

.brand-slogan {
    @extend .gamma;

    margin: $bsu-s 0 0;
    padding-right: $bsu;

    color: $brand-alt-color;

    // float: right;
    float: left;
    text-align: right;
}


.page-header__navigation {
    @extend .clearfix;

    margin-top: $bsu-s;

    padding-left: $bsu;
    padding-right: $bsu;

    background: $color-gray-dark;

    vertical-align: middle;

    &__list {
        @extend .list-bare;
        @extend .zeta;
        margin-bottom: $bsu-xs;

        margin-top: .5*$bsu-xs;

        > li {
            display: inline-block;
            width: auto;

            a {
                color: white;
                display: block;
                padding: $bsu-xs;

            }
            &.current-menu-item, a:hover {
                &, a {
                    color: $brand-alt-color;
                }
            }
        }


        &.float--right {
            width: 100%;

            // > li {
            //     width: 30%;
            // }

            @include media-query(palm-wide-and-up) {
                width: auto;
                > li {
                    width: auto;
                }
            }
        }
    }
}

.searchform {
    @extend .clearfix;

    display: inline-block;
    min-width: 200px;

    vertical-align: middle;

    @include media-query(lap-and-up) {
        margin-left: $bsu-l;
    }

    &__input, &__submit {
        float: left;
        display: block;
        background: none;
        box-shadow: none;
        margin-bottom: 0;
        -webkit-appearance: normal;

        border: 1px solid $color-gray;

        padding-top: .5*$bsu-xs;
        padding-bottom: .5*$bsu-xs;

        height: $bsu-xs + $bsu - 3px;
        box-sizing: border-box !important;
    }

    &__input {
        width: 70%;
        border-right-width: 0;

        color: white;
        box-sizing: border-box;
    }

    &__submit {
        width: auto;
        color: $color-gray;
        border-left-width: 0;

        height: $bsu-xs + $bsu - 3px;
        // padding-top: $bsu-xs;
        // padding-bottom: $bsu-xs;

    }

}
