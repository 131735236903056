
.content-list {
    padding: $bsu;

    &__item {
        @extend  .accordion;

        position: relative;

        margin-bottom: $bsu;


        padding-bottom: $bsu;
        border-bottom: 1px solid $brand-alt-color;

        &:last-child {
            margin-bottom: 0;
        }
        &:last-child, &:last-of-type {
            border-bottom-width: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }

        &.accordion {
            .content-list__item__title {
                // margin-bottom: 0;
            }
            &.accordion--active {
                .content-list__item__title {
                    // margin-bottom: $bsu;
                }
            }

            .accordion__body {
                // padding-top: $bsu;
            }
        }


        &__title {
            @extend .gamma;
            // margin-bottom: 0;

            & + .share-list {
                @extend .share-list--inline;

                position: absolute;
                bottom: $bsu-s;
                right: 0;

                @include media-query(lap-and-up) {
                    bottom: auto;
                    // right: 0;
                    top: 0;
                }

            }
        }


        &__subtitle {
            @extend .color-brand-alt;
        }

    }
}

@media screen and (max-width: 50em) {
    .content-list__item__title + .share-list {
        bottom: 0;
        top: auto;
    }
}
