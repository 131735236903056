
.page-footer {
    padding: $bsu;

    // color: lighten($color-gray, 5);
    &, a {
        color: #959b9d;
    }

    background: $color-gray-dark;

    @include inuit-font-size(14px, false);

    a {
        transition: color .1 ease-in-out;
        &:hover {
            color: $brand-alt-color;
        }
    }

    // .list-block {
    //     > li {
    //         padding: .5*$bsu-xs;
    //     }
    // }

    .list-two-col {
        ul {
            @extend .clearfix;
            @extend .list-bare;
            display: flex;
            flex-wrap: wrap;

            > li {
                width: 48%;
                margin-right: 2%;
                display: inline-block;

                &:nth-child(2n) {
                    width: 46%;
                    margin-left: 4%;
                    margin-right: 0;
                }
            }
        }
    }
}

.brand--small {
    @extend .brand;
    float: left;

    padding-left: $bsu-s;
    padding-right: $bsu-s;

    // @include media-query(lap-and-up) {
        max-width: 150px;
    // }
}



