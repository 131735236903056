
img[width]{
    max-width: 100%;

    width: auto;
    height: auto;
}

.wp-caption-text {
    color: $brand-alt-color;
}

.ninja-forms-form {
    @include media-query(lap-and-up) {
        width: 60%;
    }
}

.widget-odd, .widget-even {
    margin-bottom: $bsu;
}

.one-whole {
    width: 100% !important;
}

.widget_nav_menu {
    ul {
        @extend .list-bare;
    }
}

body.fidessecur-aktuell-online .page-content article {
    padding: $bsu;
}



