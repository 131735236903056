@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?o2502r');
    src:    url('../fonts/icomoon.eot?o2502r#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?o2502r') format('truetype'),
        url('../fonts/icomoon.woff?o2502r') format('woff'),
        url('../fonts/icomoon.svg?o2502r#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
    content: "\e900";
}
.icon-envelope:before {
    content: "\e608";
}
.icon-zoom:before {
    content: "\e606";
}
.icon-key:before {
    content: "\e607";
}
.icon-arrow-down:before {
    content: "\e600";
}
.icon-arrow-left:before {
    content: "\e601";
}
.icon-arrow-right:before {
    content: "\e602";
}
.icon-arrow-up:before {
    content: "\e603";
}
.icon-print:before {
    content: "\e604";
}
.icon-star:before {
    content: "\e605";
}

.icon {
    // vertical-align: middle;

    &--large {
        @include inuit-font-size($bsu, false);
    }
}
