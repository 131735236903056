
.btn {
    background-color: $brand-alt-color;

    transition: all .2s ease-in-out;

    &:before {
        @extend .icon;
        content: "\e602";
        margin-right: $bsu-xs;
    }

    &:hover {
        background-color: darken($brand-alt-color, 10);
    }
}
