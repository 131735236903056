/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

/**
 * A simple button object.
 */

// Predefine the variables below in order to alter and enable specific features.
$inuit-btn-namespace:       $inuit-namespace !default;

$inuit-btn-padding:         halve($inuit-base-spacing-unit) !default;
$inuit-btn-padding--small:  halve($inuit-btn-padding) !default;
$inuit-btn-padding--large:  double($inuit-btn-padding) !default;
$inuit-btn-background:      #4a8ec2 !default;
$inuit-btn-color:           #fff !default;
$inuit-btn-border-width:    1px !default;
$inuit-btn-border-style:    solid !default;
$inuit-btn-border-color:    $inuit-btn-background !default;
$inuit-btn-radius:          0 !default;

$inuit-enable-btn--small:   false !default;
$inuit-enable-btn--large:   false !default;
$inuit-enable-btn--full:    false !default;
$inuit-enable-btn--pill:    false !default;





// Here we set a variable assuming that `box-sizing: border-box;` is not set
// globally. If it has been previously been defined, the following variable will
// be overriden and will be set to `true`.
$inuit-global-border-box: false !default;





/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 * 6. Fixes odd inner spacing in IE7.
 * 7. Subtract the border size from the padding value so that buttons do not
 *    grow larger as we add borders.
 */
.#{$inuit-btn-namespace}btn,
%#{$inuit-btn-namespace}btn {
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    font: inherit; /* [3] */
    text-align: center; /* [4] */
    margin:  0; /* [4] */
    cursor: pointer; /* [5] */
    overflow: visible; /* [6] */
    padding: $inuit-btn-padding - $inuit-btn-border-width  double($inuit-btn-padding) - $inuit-btn-border-width; /* [7] */
    background-color: $inuit-btn-background;

    @if($inuit-btn-border-width != 0) {
        border: $inuit-btn-border-width $inuit-btn-border-style $inuit-btn-border-color;
    } @else {
        border: none; /* [4] */
    }

    @if($inuit-btn-radius != 0) {
        border-radius: $inuit-btn-radius;
    }

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none; /* [4] */
        color: $inuit-btn-color;
    }

}

/**
 * Fix a Firefox bug whereby `input type="submit"` gains 2px extra padding.
 */
.#{$inuit-btn-namespace}btn::-moz-focus-inner,
%#{$inuit-btn-namespace}btn::-moz-focus-inner {
    border:  0;
    padding: 0;
}





@if ($inuit-enable-btn--small == true) {

    /**
     * Small buttons.
     */

    .#{$inuit-btn-namespace}btn--small,
    %#{$inuit-btn-namespace}btn--small {
        padding: $inuit-btn-padding--small - $inuit-btn-border-width  double($inuit-btn-padding--small) - $inuit-btn-border-width; /* [7] */
    }

}

@if ($inuit-enable-btn--large == true) {

    /**
     * Large buttons.
     */

    .#{$inuit-btn-namespace}btn--large,
    %#{$inuit-btn-namespace}btn--large {
        padding: $inuit-btn-padding--large - $inuit-btn-border-width  double($inuit-btn-padding--large) - $inuit-btn-border-width; /* [7] */
    }

}





@if ($inuit-enable-btn--full == true) {

    /**
     * Full-width buttons.
     */

    .#{$inuit-btn-namespace}btn--full,
    %#{$inuit-btn-namespace}btn--full {
        width: 100%;

        @if $inuit-global-border-box == false {
            /**
             * Remove paddings so that widths and paddings don’t conflict.
             */
            padding-right: 0;
            padding-left:  0;
        }

    }

}





@if ($inuit-enable-btn--pill == true) {

    /**
     * Round-ended buttons.
     *
     * 1. Overly-large value to ensure the radius rounds the whole end of the
     *    button.
     */

    .#{$inuit-btn-namespace}btn--pill,
    %#{$inuit-btn-namespace}btn--pill {
        border-radius: 100px; /* [1] */
    }

}
