
form {


}

input, textarea {
    padding: $bsu-xs;
    margin-bottom: $bsu-s;

    border: 1px solid $color-gray;

    transition: all .1s ease-in-out;

    &:focus {
        outline: 1px solid $brand-alt-color;
        border-color: $brand-alt-color;
    }
}

textarea {
    min-height: 180px;
    resize: none;
}

label, input, textarea {
    display: block;
    width: 100%;
}

form input[type="radio"],
form input[type="checkbox"] {
    width: auto;
}

input[type="submit"],
button.btn--submit {
    @extend .btn;

}
