

$inuit-heading-size-1:  36px !default;
$inuit-heading-size-2:  30px !default;
$inuit-heading-size-3:  24px !default;
$inuit-heading-size-4:  20px !default;
$inuit-heading-size-5:  16px !default;
$inuit-heading-size-6:  14px !default;

h1,
.#{$inuit-namespace}alpha,
%#{$inuit-namespace}alpha {
    @include inuit-font-size($inuit-heading-size-1, $heading-line-height-factor);
}

h2,
.#{$inuit-namespace}beta,
%#{$inuit-namespace}beta {
    @include inuit-font-size($inuit-heading-size-2, $heading-line-height-factor);
}

h3,
.#{$inuit-namespace}gamma,
%#{$inuit-namespace}gamma {
    @include inuit-font-size($inuit-heading-size-3, $heading-line-height-factor);
}

h4,
.#{$inuit-namespace}delta,
%#{$inuit-namespace}delta {
    @include inuit-font-size($inuit-heading-size-4, $heading-line-height-factor);
}

h5,
.#{$inuit-namespace}epsilon,
%#{$inuit-namespace}epsilon {
    @include inuit-font-size($inuit-heading-size-5, $heading-line-height-factor);
}

h6,
.#{$inuit-namespace}zeta,
%#{$inuit-namespace}zeta {
    @include inuit-font-size($inuit-heading-size-6, $heading-line-height-factor);
}


h1, .alpha, h2, .beta, h3, .gamma {
    @extend %uppercase;

    font-weight: 400;
}
